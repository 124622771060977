import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  :root {
    --black: #2E2E2E;
    --white: #fff;
    --grey-1: #F4F4F2;
    --grey-2: #EEEEEB;
    --grey-3: #deded9;
    --grey-3b: #d6d6ce;
    --grey-4: #828282;

    --primary-font: 'Cormorant Garamond', serif;
    --secondary-font: 'Source Sans Pro', sans-serif;
  }

html { 
   font-size: 62.5%;/*for using REM units*/
}

body {
   font-family: var(--primary-font);
   font-size: 1.8rem;
   font-weight: 400;
   line-height: 1.3;
   color: var(--black);
   background: var(--grey-1);
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--secondary-font);
  font-weight: 400;
  margin: 1.3em 0 .7em 0;
}

p {
  line-height: 1.5;
}

.image-responsive {
  max-width: 100%; 
  display:block; 
  height: auto;
  flex-shrink: 0; /* stops ie stretching images in a flex container */
  margin: 0 auto 2rem auto;
}

.link {
  border-bottom: 1px solid;
}

`;

export default GlobalStyles;
