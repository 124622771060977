import React from 'react';
import styled from 'styled-components';
import { media } from '../utils/style-utils';

const NavItem = styled.nav`
  padding: 1rem;
  font-size: 1.8rem;
  font-family: var(--secondary-font);

  ${media.desktop`
    padding: 1rem 3rem;
    font-size: 2rem;
    `}
`;

export default ({ children }) => (
  <NavItem>
    <ul>
      <li>{children}</li>
    </ul>
  </NavItem>
);
