import React from 'react';
import styled from 'styled-components';
import { media } from '../utils/style-utils';

const LayoutSection = styled.section`
  background: ${(props) => (props.isWhite ? 'var(--white)' : '')};
  padding: 0 2rem;
  ${media.hd`
    padding: 2rem 0 5rem 0; 
  `}
`;

const LayoutWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

export default ({ children, isWhite }) => (
  <LayoutSection isWhite={isWhite}>
    <LayoutWrapper>{children}</LayoutWrapper>
  </LayoutSection>
);
