import React from 'react';
import styled from 'styled-components';
import Header from './Header';
import GlobalStyles from '../styles/GlobalStyles';
import Normalise from '../styles/Normalise';

const Layout = styled.div``;

const Footer = styled.footer`
  padding: 2rem;
  /* background: var(--grey-3); */
  border-top: 1px solid var(--grey-3);
  font-family: var(--secondary-font);
  text-align: center;
  font-size: 1.5rem;
`;

export default ({ children }) => (
  <>
    <GlobalStyles />
    <Normalise />
    <Layout>
      <Header />
      <main>{children}</main>
      <Footer>
        <p>&copy; {new Date().getFullYear()}</p>
        <p>
          Agent:{' '}
          <a href="http://www.andrewlownie.co.uk/" target="_blank">
            Andrew Lownie
          </a>
        </p>
      </Footer>
    </Layout>
  </>
);
