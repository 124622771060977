import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import logo from '../assets/images/ds-logo.svg';
import logoMobile from '../assets/images/ds-logo-mobile.svg';
// import magnifyer from '../assets/images/magnifyer.svg';
import NavItem from './NavItem';
import { media } from '../utils/style-utils';

const Header = styled.header`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 1rem 2rem;
  background: var(--white);
  border-bottom: 1px solid var(--grey-2);
  height: 100px;
  position: sticky;
  top: 0;
  z-index: 1;

  & > *:first-child,
  & > *:last-child {
    flex: 0 0 25%;
  }

  & > *:last-child {
    text-align: right;
  }
`;

const Branding = styled(Link)`
  width: 60px;
  height: 55px;
  background: url(${logoMobile}) no-repeat;
  background-size: contain;
  background-position: center;
  flex: 1;
  text-align: center;
  margin-top: 0.5rem;

  ${media.desktop`
    background-image: url(${logo});
    `}
`;

const Tagline = styled.span`
  flex: 1;
  text-transform: uppercase;
  letter-spacing: 0.3em;
  font-size: 1.5rem;
  display: inline-block;
  color: var(--grey-4);
  font-family: var(--secondary-font);
`;

export default (props) => (
  <Header>
    <NavItem>
      <Link to="/">About</Link>
    </NavItem>
    <Branding to="/" />
    <NavItem>
      <Link to="/books">Books</Link>
    </NavItem>
  </Header>
);
